.App {
  /* text-align: center; */
}


.app-alert {
  border-radius: 10px;
  background-color: #4845f5fd;
  /* min-height: 100vh; */
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding:5px;
  /* font-weight: bold; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


header {
  background-color:#003300;
  color:white;
  text-align:center;
  padding:5px;
}
nav {
  width: 10%;
  visibility: hidden;
  line-height:30px;
  background-color:#eeeeee;
  height:300px;
  float:left;
  padding:5px;
}
section {
  width:80%;
  float:left;
  padding:10px;
  background-color: white;
  border-radius: 10px;
  background-image: url("background.png");
}
footer {
  background-color:#003300;
  color:white;
  clear:both;
  text-align:center;
  padding:5px;
}

body {
  background-color: #003300;
}
